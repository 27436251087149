import React, { FC } from 'react';

import ProductItem from 'gatsby-theme-dettol/src/components/common/ProductItem';
import HTag from 'gatsby-theme-dettol/src/components//common/HTag';
import DangerouslySetInnerHtml from 'gatsby-theme-dettol/src/components/common/DangerouslySetInnerHtml';
import { IRecommendedProducts } from 'gatsby-theme-dettol/src/components/common/Article/RecommendedProducts/models';
import 'gatsby-theme-dettol/src/components/common/Article/RecommendedProducts/RecommendedProducts.scss';
import './RecommendedProducts.scss';

const RecommendedProducts: FC<IRecommendedProducts> = ({
  startText,
  endText,
  productList,
  header,
}) => (
  <div className="dt-recommended">
    <HTag underline size={2} regularText={header.regularText} boldText={header.boldText} />
    <DangerouslySetInnerHtml html={startText} className="dt-recommended-start" />
    <div className="dt-recommended__items">
      {productList.map((item) => (
        <ProductItem key={item.id} {...item} />
      ))}
    </div>
    <DangerouslySetInnerHtml html={endText} className="dt-recommended-end" />
  </div>
);

export default RecommendedProducts;
